import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './PesquisaContratoForm.scss';
import {Contrato} from "../../../interfaces/Contrato";
import {useLocation, useNavigate} from 'react-router-dom';

export declare interface PesquisaContrato {
    ano: string;
    categoria: string;
    subcategoria: string;
    parametro: string;
}

declare interface PesquisaContratoProps {
    form?: PesquisaContrato,
    categoria: string[],
    subcategoria: string[],
    ano: string[],
    onSubmit?: (contrato: PesquisaContrato) => void,
    onSelectAno: (ano: string) => void,
    onSelectCategoria: (categoria: string) => void,
    onSelectSubCategoria: (subcategoria: string) => void,
    onSelectParametro: (parametro: string) => void,
    anoSelecionado: string,
    categoriaSelecionada: string,
    subCategoriaSelecionada: string,
    parametroSelecionado: string,
    setContratos: React.Dispatch<React.SetStateAction<Contrato[]>>;
}

const PesquisaContratoForm: React.FC<PesquisaContratoProps> = (props) => {
    const [validated, setValidated] = useState(false);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            const pesquisaContrato: PesquisaContrato = {
                ano: props.anoSelecionado !== '' ? props.anoSelecionado : '',
                categoria: props.categoriaSelecionada !== '' ? props.categoriaSelecionada : '',
                subcategoria: props.subCategoriaSelecionada !== '' ? props.subCategoriaSelecionada : '',
                parametro: props.parametroSelecionado !== '' ? props.parametroSelecionado: '',
            };

            if (props.onSubmit && (pesquisaContrato.ano !== '' || pesquisaContrato.categoria !== '' || pesquisaContrato.subcategoria !== '' || pesquisaContrato.parametro !== '')) {
                props.onSubmit(pesquisaContrato);
                navigate(`${location.pathname}?page=1`);
            } else {
                console.error("props.onSubmit não é uma função ou nenhum parâmetro preenchido");
            }
        }

        setPaginaAtual(1);
        setValidated(true);
    };



    const handleAnoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onSelectAno(event.target.value);
    };

    const handleCategoriaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onSelectCategoria(event.target.value);
    };

    const handleSubCategoriaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onSelectSubCategoria(event.target.value);
    };

    const handleParametroChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onSelectParametro(event.target.value)
    }

    const handleLimpar = () => {
        props.onSelectAno('');
        props.onSelectCategoria('');
        props.onSelectSubCategoria('');
        props.onSelectParametro('');
        props.setContratos([]);

        setValidated(false);

        const form = document.getElementById('pesquisaContratoForm') as HTMLFormElement | null;
        if (form) {
            form.reset();
        }
    };


    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="AppForm">
            <Row className="mb-12">
                <Form.Group as={Col} md="2" controlId="ano">
                    <Form.Label>Ano:</Form.Label>
                    <Form.Select defaultValue="" onChange={handleAnoChange}>
                        <option value=""></option>
                        {props.ano.map((item, i) => (
                            <option key={i} value={item}>
                                {item}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">Informe o ano.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="categoria">
                    <Form.Label>
                        Categoria: <span className="required">*</span>
                    </Form.Label>
                    <Form.Select defaultValue="" onChange={handleCategoriaChange} required>
                        <option value="">Selecione</option>
                        {props.categoria.map((item, i) => (
                            <option key={i} value={item}>
                                {item}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        Por favor, selecione os filtros obrigatórios.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="subcategoria">
                    <Form.Label>Subcategoria:</Form.Label>
                    <Form.Select defaultValue="" onChange={handleSubCategoriaChange}>
                        <option value="">Selecione</option>
                        {props.subcategoria.map((item, i) => {
                            return <option key={i} value={item}>{item}</option>;
                        })}
                    </Form.Select>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="parametro">
                    <Form.Label>Busca por parâmetro:</Form.Label>
                    <Form.Control type="text" value={props.parametroSelecionado} onChange={handleParametroChange} />
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="btn">
                    <Button variant="light" type="submit" >
                        Pesquisar
                    </Button>
                    <Button variant="light" type="reset" onClick={handleLimpar}>
                        Limpar
                    </Button>
                </Form.Group>
            </Row>
        </Form>
    );
};
export default PesquisaContratoForm;
