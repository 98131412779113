import React, {useEffect, useState} from "react";
import PesquisaContratoForm from "./Pesquisa";
import TableContrato from "./Table";
import Detalhe from "./Detalhe";
import {
    getAllCategoriasContratosFisac,
    getAllContratosFisac,
    getAllSubcategoriasContratosFisac
} from "../../services/Contratos.service";
import {Contrato} from "../../interfaces/Contrato";
import Resultado from "./Resultado/Resultado";
import {ContratosParams} from "../../interfaces/ContratosParams";
import {PesquisaContrato} from "./Pesquisa/PesquisaContratoForm";

const TransparenciaContratos = () => {

    const headersContratos = [
        {key: 'numContrato', value: 'N° Contrato'},
        {key: 'categoria', value: 'Categoria'},
        {key: 'subCategoria', value: 'Subcategoria'},
        {key: 'objeto', value: 'Objeto'},
        {key: 'cnpjFornecedor', value: 'CNPJ/CPF'},
        {key: 'fornecedor', value: 'Fornecedor'},
        {key: 'vigenciaOriginal', value: 'Vigência original', breakline: true},
        {key: 'vigenciaAtual', value: 'Vigência atual', breakline: true}
    ]

    const [buttonPopUp, setButtonPopUp] = useState(false);
    const [contratos, setContratos] = useState<Contrato[]>([]);
    const [categorias, setCategorias] = useState<string[]>([]);
    const [subcategorias, setSubcategorias] = useState<string[]>([]);
    const [anos, setAnos] = useState<string[]>([]);
    const [contrato, setContrato] = useState<Contrato | null>(null);
    const [anoSelecionado, setAnoSelecionado] = useState<string>('');
    const [categoriaSelecionada, setCategoriaSelecionada] = useState<string>('');
    const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState<string>('');
    const [showNoResultsModal, setShowNoResultsModal] = useState(false);
    const [parametroSelecionado, setParametroSelecionado] = useState<string>('');

    useEffect(() => {
        async function fetchAnos() {
            try {
                const contratos = await getAllContratosFisac();
                const anos = contratos.map((c) => c.ano).filter(Boolean) as string[];
                const categorias = await getAllCategoriasContratosFisac();

                setCategorias(ajusteArray(categorias));
                setAnos(ajusteArray(anos));
                handleSearch();

            } catch (err) {
                console.log('Erro ao carregar contratos:', err);
            }
        }

        fetchAnos();
    }, []);

    useEffect(() => {
        async function fetchSubcategorias() {
            try {
                if (categoriaSelecionada !== '') {
                    const subcategoriasObtidas = await getAllSubcategoriasContratosFisac(categoriaSelecionada);
                    const subcategoriasAjustadas = ajusteArray(subcategoriasObtidas);
                    setSubcategorias(subcategoriasAjustadas);
                    setSubCategoriaSelecionada('');
                }
            } catch (err) {
                console.log('Erro ao carregar subcategorias:', err);
            }
        }

        fetchSubcategorias();
    }, [categoriaSelecionada]);

    const handleSearch = async () => {
         try {
             if (categoriaSelecionada !== '') {
                 const searchParams: ContratosParams = {}; // Objeto para armazenar os parâmetros da busca

                 if (anoSelecionado !== '') {
                     searchParams.ano = anoSelecionado;
                 }
                 if (categoriaSelecionada !== '') {
                     searchParams.categoria = categoriaSelecionada;
                 }
                 if (subCategoriaSelecionada !== '') {
                     searchParams.subcategoria = subCategoriaSelecionada;
                 }
                 if (parametroSelecionado !== ''){
                     searchParams.parametro = parametroSelecionado;
                 }

                 const contratos = await getAllContratosFisac(searchParams);

                 if (contratos.length === 0) {
                     setShowNoResultsModal(true);
                 } else {
                     setContratos(contratos);
                     setShowNoResultsModal(false);
                 }
             }
         } catch (err) {
             console.log('Erro ao carregar contratos:', err);
             setShowNoResultsModal(false);
         }
     };


    function ajusteArray(arr: string[]) {
        arr = arr.filter((v, i, a) => a.indexOf(v) === i)
            .sort((a, b) => {
                return a.toLowerCase() < b.toLowerCase()
                    ? -1
                    : 1
            })
        return arr;
    }

    const handlePesquisaSubmit = async (pesquisaContrato: PesquisaContrato) => {
        try {

            if (anoSelecionado !== undefined && categoriaSelecionada !== undefined && subCategoriaSelecionada !== undefined && parametroSelecionado !== undefined) {
                const searchParams: ContratosParams = {
                    ano: anoSelecionado,
                    categoria: categoriaSelecionada,
                    subcategoria: subCategoriaSelecionada,
                    parametro: parametroSelecionado,
                };

                const contratos = await getAllContratosFisac(searchParams);

                if (contratos.length === 0) {
                    setShowNoResultsModal(true);
                    setContratos([])
                } else {
                    setContratos(contratos);
                    setShowNoResultsModal(false);
                }
            } else {
                console.error("Um ou mais estados não estão definidos!");
            }
        } catch (err) {
            console.log('Erro ao carregar contratos:', err);
        }
    };



    return (
        <>
            <PesquisaContratoForm
                categoria={categorias}
                subcategoria={subcategorias}
                ano={anos}
                onSelectAno={setAnoSelecionado}
                onSelectCategoria={setCategoriaSelecionada}
                onSelectSubCategoria={setSubCategoriaSelecionada}
                onSelectParametro={setParametroSelecionado}
                anoSelecionado={anoSelecionado}
                categoriaSelecionada={categoriaSelecionada}
                subCategoriaSelecionada={subCategoriaSelecionada}
                parametroSelecionado={parametroSelecionado}
                onSubmit={handlePesquisaSubmit}
                setContratos={setContratos}
            />
            <TableContrato
                headers={headersContratos}
                data={contratos}
                enableActions
                onDetail={(row) => {
                    setButtonPopUp(true);
                    setContrato(row.$original);
                }}
            />
            <Detalhe trigger={buttonPopUp} setTrigger={setButtonPopUp} model={contrato} />
            <Resultado show={showNoResultsModal} onClose={() => setShowNoResultsModal(false)} />
        </>
    );
};

export default TransparenciaContratos;