import React, {useEffect, useState} from "react";
import "./TableContrato.scss";
import organizeData from "../../../utils/organizeDataForTable";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import paginate from "../../../utils/paginate";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import queryString from "query-string";

export interface TableHeader {
    key: string;
    value: string;
    left?: boolean;
    right?: boolean;
    center?: boolean;
    breakline?: boolean;
}

declare interface TableProps {
    headers: TableHeader[];
    data: any[];

    enableActions?: boolean;

    onDelete?: (item: any) => void;
    onDetail?: (item: any) => void;
    onEdit?: (item: any) => void;
}

const TableContrato: React.FC<TableProps> = (props) => {
    const [organizedData, indexedHeaders] = organizeData(
        props.data,
        props.headers
    );
    const [, setButtonPopUp] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const page = parseInt(queryString.parse(location.search).page as string) || 1;
    const totalPages = Math.ceil(organizedData.length / itemsPerPage);
    const paginatedData = paginate(organizedData, itemsPerPage, page);
    const startIndex = organizedData.length > 0 ? (page - 1) * itemsPerPage + 1 : 0;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, organizedData.length);



    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        const selectedItemsPerPage = parseInt(
            queryParams.itemsPerPage as string
        ) || 10;
        setItemsPerPage(selectedItemsPerPage);
    }, [location.search]);

    const handleItemsPerPageChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const selectedItemsPerPage = parseInt(e.target.value);
        setItemsPerPage(selectedItemsPerPage);
        navigate(
            `${location.pathname}?page=${page <= totalPages ? page : totalPages}&itemsPerPage=${selectedItemsPerPage}`
        );
    };

    return (
        <>
            <div>
                <Row className="mb-12 registros">
                    <Form.Group as={Col} md="3">
                        <div className="registros">
                            Mostrar
                            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                            </select>
                            Registros
                        </div>
                    </Form.Group>
                </Row>

                <Table responsive className="AppTable">
                    <thead>
                    <tr>
                        {props.headers.map((header) => (
                            <th
                                className={
                                    header.right
                                        ? "right"
                                        : header.left
                                            ? "left"
                                            : header.breakline
                                                ? "breakline"
                                                : ""
                                }
                                key={header.key}
                                style={{ textTransform: 'none', fontSize: '16px' }}
                            >
                                {header.value.charAt(0).toUpperCase() + header.value.slice(1)}
                            </th>
                        ))}
                        {props.enableActions && <th className="center" style={{ textTransform: 'none', fontSize: '16px' }}>Detalhes </th>}
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedData.map((row, i) => (
                        <tr key={i}>
                            {Object.keys(row).map((item, i) => {
                                if (item !== "$original") {
                                    if (
                                        item === "dataVigenciaInicio" &&
                                        row["$original"].idContrato != null &&
                                        "dataVigenciaInicio" in row["$original"]
                                    ) {
                                        const dataVigenciaInicio = row["$original"].dataVigenciaInicio;

                                        return (
                                            <td
                                                key={row["$original"].idContrato + i}
                                                className={
                                                    indexedHeaders[item].right
                                                        ? "right"
                                                        : indexedHeaders[item].left
                                                            ? "left"
                                                            : indexedHeaders[item].breakline
                                                                ? "breakline"
                                                                : ""
                                                }
                                            >
                                                {dataVigenciaInicio != null
                                                    ? `${dataVigenciaInicio} à ${row["$original"].dataVigenciaFim}`
                                                    : ''}
                                            </td>
                                        );
                                    } else if (row["$original"].idContrato == null) {
                                        return <td key={i}> &#8203; </td>;
                                    }
                                    if (
                                        item === "dataInicioContrato" &&
                                        row["$original"].idContrato != null
                                    ) {
                                        return (
                                            <td
                                                key={row["$original"].idContrato + i}
                                                className={
                                                    indexedHeaders[item].right
                                                        ? "right"
                                                        : indexedHeaders[item].left
                                                            ? "left"
                                                            : indexedHeaders[item].breakline
                                                                ? "breakline"
                                                                : ""
                                                }
                                            >
                                                {row[item] +
                                                    " à " +
                                                    row["$original"].dataFimContrato}
                                            </td>
                                        );
                                    } else if (row["$original"].idContrato == null) {
                                        return <td key={i}> &#8203; </td>;
                                    }
                                    return (
                                        <td
                                            key={row["$original"].idContrato + i}
                                            className={
                                                indexedHeaders[item].center ? "center" : ""
                                            }
                                        >
                                            {row[item]}
                                        </td>
                                    );
                                }
                                return null;
                            })}
                            {props.enableActions && (
                                <td className="actions center">
                                    {props.onDetail &&
                                        row["$original"].idContrato != null && (
                                            <button
                                                onClick={() => {
                                                    if (props.onDetail) {
                                                        props.onDetail(row);
                                                        setButtonPopUp(true);
                                                    }
                                                }}
                                            >
                                                <img
                                                    width="16"
                                                    src="https://img.icons8.com/pastel-glyph/2x/search--v2.png"
                                                    alt="search icon"
                                                />
                                            </button>
                                        )}
                                </td>
                            )}
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Row className="mb-12">
                    <Form.Group as={Col} md="3">
                        <div className="Table_pagination_desc">{`Mostrando ${startIndex} até ${endIndex} de ${organizedData.length} registros`}</div>
                    </Form.Group>
                    <Form.Group as={Col} md="9">
                        <div className="Table_pagination">
                            <NavLink
                                className="nav"
                                key="anterior"
                                to={`${location.pathname}?page=${page > 1 ? page - 1 : 1}&itemsPerPage=${itemsPerPage}`}
                            >
                                Anterior
                            </NavLink>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
                                <NavLink
                                    key={pageNumber}
                                    className={pageNumber === page ? "selected" : ""}
                                    to={`${location.pathname}?page=${pageNumber}&itemsPerPage=${itemsPerPage}`}
                                >
                                    {pageNumber}
                                </NavLink>
                            ))}
                            <NavLink
                                className="nav"
                                key="seguinte"
                                to={`${location.pathname}?page=${page < totalPages ? page + 1 : totalPages}&itemsPerPage=${itemsPerPage}`}
                            >
                                Seguinte
                            </NavLink>
                        </div>
                    </Form.Group>
                </Row>
            </div>
        </>
    );
};

export default TableContrato;
