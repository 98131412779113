import React, {useEffect} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import ContratosView from "../../views/ContratosView";

function App() {

    useEffect(() => {
        document.title = 'Portal Transparência';
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="*" index element={<Navigate to="/portal-fisac" />} />
                    <Route path="/portal-fisac" element={<ContratosView/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
