import React from "react";
import ContratosDataTableView from "./ContratosDataTableView";

const ContratosView = () => {

    return (
        <>
            <ContratosDataTableView></ContratosDataTableView>
        </>
    );

}

export default ContratosView