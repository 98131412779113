// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form.AppForm {
  margin-top: 25px;
}
form.AppForm label {
  float: left;
}
form.AppForm button {
  margin-top: 2rem;
  margin-left: 0.5em;
  float: left;
  color: #0b0e0b;
}
form.AppForm button:first-child {
  background-color: #cfffcf;
  border-color: #bbffbb;
}
form.AppForm button:last-child {
  background-color: #e8e8e8;
  border-color: #dddddd;
}
form.AppForm .required {
  color: red;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Contratos/Pesquisa/PesquisaContratoForm.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,WAAA;AACJ;AAEE;EACE,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;AAAJ;AAGE;EACE,yBAAA;EACA,qBAAA;AADJ;AAIE;EACE,yBAAA;EACA,qBAAA;AAFJ;AAKE;EACE,UAAA;EACA,iBAAA;AAHJ","sourcesContent":["form.AppForm {\r\n  margin-top: 25px;\r\n\r\n  label {\r\n    float: left;\r\n  }\r\n\r\n  button {\r\n    margin-top: 2rem;\r\n    margin-left: 0.5em;\r\n    float: left;\r\n    color: #0b0e0b;\r\n  }\r\n\r\n  button:first-child {\r\n    background-color: #cfffcf;\r\n    border-color: #bbffbb;\r\n  }\r\n\r\n  button:last-child {\r\n    background-color: #e8e8e8;\r\n    border-color: #dddddd;\r\n  }\r\n\r\n  .required {\r\n    color: red;\r\n    font-weight: bold;\r\n  }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
