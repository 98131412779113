import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import "./Detalhe.scss"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TableDetalhe from "./Table";
import {Contrato} from "../../../interfaces/Contrato";
import {downloadArquivosContrato} from "../../../services/Contratos.service";

const headersAditivos = [
    {key: 'descricaoAditivo', value: 'Descrição', left: true},
    {key: 'tipoAditivo', value: 'Tipo de Aditivo'},
    {key: 'classificacaoAditivo', value: 'Classificação do Aditivo'},
    {key: 'valor', value: 'Valor Aditivo'},
    {key: 'prazoAditivo', value: 'Prazo', center: true}
]

const headersApostilamentos = [
    {key: 'numero', value: 'N° do Apostilamento', left: true},
    {key: 'tipo', value: 'Tipo'},
    {key: 'descricao', value: 'Descrição'},
    {key: 'apostila', value: 'Apostila'},
    {key: 'valor', value: 'Valor Atual do Contrato', center: true}
]

function Detalhe(props: any) {
    const contrato: Contrato | null = props.model;
    const [downloadError, setDownloadError] = React.useState(false);
    const [downloadSuccess, setDownloadSuccess] = React.useState(false);

    const handleDownloadContrato = async () => {
        setDownloadError(false);
        setDownloadSuccess(false);

        if (contrato && contrato.idContrato) {
            try {
                const response = await downloadArquivosContrato(contrato.idContrato);
                if (response.status === 200) {
                    setDownloadSuccess(true);
                }
            } catch (error) {
                console.error('Erro ao fazer o download do contrato:', error);
                setDownloadError(true);
            }
        }
    };

    React.useEffect(() => {
        setDownloadError(false);
        setDownloadSuccess(false);
    }, [contrato]);


    return (contrato != null) ? (
        <>
            <Modal size="lg" className="AppDetalhe" show={props.trigger} onHide={() => props.setTrigger(false)}
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes do Contrato</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-12">
                        <Form.Group as={Col} md="4">
                            <Form.Label>Categoria:</Form.Label>
                            <Form.Control type="text" value={contrato.categoria!}/>
                        </Form.Group>

                        <Form.Group as={Col} md="4">
                            <Form.Label>Subcategoria:</Form.Label>
                            <Form.Control type="text" value={contrato.subCategoria!}/>
                        </Form.Group>

                        <Form.Group as={Col} md="4">
                            <Form.Label>Número:</Form.Label>
                            <Form.Control type="text" value={contrato.numContrato!}/>
                        </Form.Group>
                    </Row>

                    <Row className="mb-12">
                        <Form.Group as={Col} md="12">
                            <Form.Label>Fornecedor:</Form.Label>
                            <Form.Control type="text" value={contrato.fornecedor!}/>
                        </Form.Group>
                    </Row>

                    <Row className="mb-12">
                        <Form.Group as={Col} md="3">
                            <Form.Label>Início:</Form.Label>
                            <Form.Control type="text" value={contrato.dataInicioContrato!}/>
                        </Form.Group>

                        <Form.Group as={Col} md="3">
                            <Form.Label>Fim:</Form.Label>
                            <Form.Control type="text" value={contrato.dataFimContrato!}/>
                        </Form.Group>

                        <Form.Group as={Col} md="3">
                            <Form.Label>Valor:</Form.Label>
                            <Form.Control type="text" value={contrato.valor!}/>
                        </Form.Group>
                    </Row>

                    <Row className="mb-12">
                        <Form.Group as={Col} md="12">
                            <Form.Label>Objeto:</Form.Label>
                            <Form.Control as="textarea" aria-label="With textarea" value={contrato.objeto!}/>
                        </Form.Group>
                    </Row>

                    {downloadSuccess && (
                        <div style={{ fontSize: '14px', color: 'green', marginTop: '15px' }}>
                            <p>Download do contrato realizado com sucesso!</p>
                        </div>
                    )}

                    {contrato.idContrato !== null && (
                        <Button
                            className="download"
                            variant="light"
                            type="button"
                            disabled={downloadSuccess} // Disable button when download is successful
                            onClick={handleDownloadContrato}
                        >
                            {downloadSuccess ? 'Download Realizado' : 'Download do Contrato'}
                        </Button>
                    )}

                    {downloadError && (
                        <div style={{ fontSize: '14px', color: 'black', marginTop: '15px' }}>
                            <p>Não há documentos anexados.</p>
                        </div>
                    )}


                </Modal.Body>
                <Tabs defaultActiveKey="aditivos" transition={false} className="mb-12 AppTab">
                    <Tab eventKey="aditivos" title="Aditivos">
                        <TableDetalhe type={"aditivos"} headers={headersAditivos} data={contrato.aditivos} enableActions
                                      onDownload={console.log}/>
                    </Tab>
                    <Tab eventKey="apostilamento" title="Apostilamento">
                        <TableDetalhe type={"apostilamentos"} headers={headersApostilamentos}
                                      data={contrato.apostilamentos!} enableActions onDownload={console.log}/>
                    </Tab>
                </Tabs>
            </Modal>
        </>
    ) : (<> </>);

}

export default Detalhe;