import React from "react";
import TransparenciaContratos from "../components/Contratos/TransparenciaContratos";
import Container from "../shared/Container";

const ContratosDataTableView = () => {

    return (
        <>
            <Container>
                <TransparenciaContratos/>
            </Container>
        </>
    );

}

export default ContratosDataTableView