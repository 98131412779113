import React from "react";
import Alert from 'react-bootstrap/Alert';
import "./TableDetalhe.scss";
import organizeData, {TableHeader} from "../../../../utils/organizeDataForTable";
import Button from "react-bootstrap/Button";
import {downloadAditivo} from "../../../../services/Contratos.service";

declare interface TableProps {
    headers: TableHeader[]
    type?: string
    data: any[]
    enableActions?: boolean
    onDownload?: (item: any) => void
}


const TableDetalhe: React.FC<TableProps> = (props) => {



    const [organizedData, indexedHeaders, organizedHeader] = organizeData(props.data, props.headers)

    const handleDownloadAditivo = async (idAditivo: string, nomeAditivo: string) => {
        try {
            await downloadAditivo(idAditivo, nomeAditivo);
        } catch (error) {
            console.error('Erro ao fazer o download do contrato:', error);
        }
    }


    return (organizedData != null && organizedData.length > 0) ? (<table className="AppTableDetalhe">
            <thead>
            <tr>
                {
                    organizedHeader.map(header =>
                        <th
                            className={header.right ? 'right' : (header.left ? 'left' : (header.center ? 'center' : ''))}
                            key={header.key}
                            style={{ textTransform: 'none', fontSize: '13px' }}
                        >
                            {header.value}
                        </th>
                    )
                }

                {
                    props.enableActions && props.type === "aditivos"
                    && <th className="center"  style={{ textTransform: 'none', fontSize: '13px' }}>
                        Arquivos do Aditivo
                    </th>
                }
            </tr>
            </thead>
            <tbody>
            {
                organizedData.map((row, i) => {
                    return <tr key={i}>
                        {
                            Object
                                .keys(row)
                                .map((item, i) => {
                                        if (item !== '$original') {
                                            return <td key={row['$original'].idAditivo + i}
                                                       className={indexedHeaders[item].center ? 'center' : ''}> {row[item]} </td>;
                                        }
                                        return null;
                                    }
                                )
                        }

                        {
                            props.enableActions &&
                            <td className="actions center">
                                {
                                    props.enableActions && props.type === "aditivos" && props.onDownload &&
                                    <Button
                                        variant="link"
                                        type="button"
                                        onClick={() => row['$original'].idAditivo != null ? handleDownloadAditivo(row['$original'].idAditivo, row['$original'].nomeArquivoAditivo) : null}
                                    >
                                        <img
                                            width="16"
                                            src={row['$original'].idAditivo != null ? 'https://img.icons8.com/metro/26/download.png' : 'https://img.icons8.com/external-flat-icons-inmotus-design/67/external-empty-mathematics-geometry-flat-icons-inmotus-design.png'}
                                            alt="download icon"
                                        />
                                    </Button>
                                }

                            </td>
                        }
                    </tr>


                })
            }
            </tbody>
        </table>) :
        (<>
            <Alert key="light" variant="light">
                Não há {props.type} para este Contrato.
            </Alert>
        </>)

}

export default TableDetalhe