// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.AppDetalhe .modal-header {
  background-color: #cfffcf;
  height: 2em;
}
div.AppDetalhe .modal-lg, div.AppDetalhe .modal-xl {
  --bs-modal-width: 800px;
}
div.AppDetalhe .h4, div.AppDetalhe h4 {
  font-size: 0.8rem;
  font-weight: 800;
}
div.AppDetalhe .form-control {
  font-size: 0.75rem;
}
div.AppDetalhe .row {
  margin-bottom: 0.6em;
}
div.AppDetalhe .form-label {
  font-size: 0.9em;
}
div.AppDetalhe input {
  pointer-events: none;
  background-color: #ebebeb;
  padding: 0.27rem 0.7rem;
}
div.AppDetalhe textarea {
  pointer-events: bounding-box;
  background-color: #ebebeb;
  height: 70px;
}
div.AppDetalhe .download {
  background-color: #5cb85c;
  border-color: #73c073;
  color: #fff;
  margin-top: 0.7em;
  font-size: 0.8em;
  --bs-btn-padding-x: 0.50rem;
  --bs-btn-padding-y: 0.2rem;
}

ul.AppTab {
  padding-left: 10px;
  padding-right: 10px;
  margin: 2px;
}
ul.AppTab .nav {
  --bs-nav-link-color: #000;
}
ul.AppTab .nav-link {
  color: #000;
}
ul.AppTab button {
  border: 2px solid #a1ffa1;
}
ul.AppTab button.active {
  border: 2px solid #a1ffa1;
  background-color: #d1ffd1 !important;
}

.tab-pane {
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Contratos/Detalhe/Detalhe.scss"],"names":[],"mappings":"AAEE;EACE,yBAAA;EACA,WAAA;AADJ;AAIE;EACE,uBAAA;AAFJ;AAKE;EACE,iBAAA;EACA,gBAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;AAOE;EACE,oBAAA;AALJ;AAQE;EACE,gBAAA;AANJ;AASE;EACE,oBAAA;EACA,yBAAA;EACA,uBAAA;AAPJ;AAUE;EACE,4BAAA;EACA,yBAAA;EACA,YAAA;AARJ;AAWE;EACE,yBAAA;EACA,qBAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,2BAAA;EACA,0BAAA;AATJ;;AAcA;EAEE,kBAAA;EACA,mBAAA;EACA,WAAA;AAZF;AAcE;EACE,yBAAA;AAZJ;AAeE;EACE,WAAA;AAbJ;AAgBE;EACE,yBAAA;AAdJ;AAiBE;EACE,yBAAA;EACA,oCAAA;AAfJ;;AAwBA;EACE,aAAA;AArBF","sourcesContent":["div.AppDetalhe {\r\n\r\n  .modal-header {\r\n    background-color: #cfffcf;\r\n    height: 2.0em;\r\n  }\r\n\r\n  .modal-lg, .modal-xl {\r\n    --bs-modal-width: 800px;\r\n  }\r\n\r\n  .h4, h4 {\r\n    font-size: 0.8rem;\r\n    font-weight: 800;\r\n  }\r\n\r\n  .form-control {\r\n    font-size: 0.75rem;\r\n  }\r\n\r\n  .row {\r\n    margin-bottom: 0.6em;\r\n  }\r\n\r\n  .form-label {\r\n    font-size: 0.9em;\r\n  }\r\n\r\n  input {\r\n    pointer-events: none;\r\n    background-color: #ebebeb;\r\n    padding: 0.27rem 0.7rem;\r\n  }\r\n\r\n  textarea {\r\n    pointer-events: bounding-box;\r\n    background-color: #ebebeb;\r\n    height: 70px;\r\n  }\r\n\r\n  .download {\r\n    background-color: #5cb85c;\r\n    border-color: #73c073;\r\n    color: #fff;\r\n    margin-top: 0.7em;\r\n    font-size: 0.8em;\r\n    --bs-btn-padding-x: 0.50rem;\r\n    --bs-btn-padding-y: 0.2rem;\r\n  }\r\n\r\n}\r\n\r\nul.AppTab {\r\n\r\n  padding-left: 10px;\r\n  padding-right: 10px;\r\n  margin: 2px;\r\n\r\n  .nav {\r\n    --bs-nav-link-color: #000;\r\n  }\r\n\r\n  .nav-link {\r\n    color: #000;\r\n  }\r\n\r\n  button {\r\n    border: 2px solid #a1ffa1;\r\n  }\r\n\r\n  button.active {\r\n    border: 2px solid #a1ffa1;\r\n    background-color: #d1ffd1 !important;\r\n  }\r\n\r\n}\r\n\r\n.tab-content {\r\n\r\n}\r\n\r\n.tab-pane {\r\n  padding: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
