export interface TableHeader {
    key: string
    value: string
    left?: boolean
    right?: boolean
    center?: boolean
    breakline?: boolean
}

type IndexedHeaders = {
    [key: string]: TableHeader
}

type OrganizedItem = {
    [key: string]: any
}

export default function organizeData(data: any[], headers: TableHeader[]):
    [OrganizedItem[], IndexedHeaders, TableHeader[]] {
    const indexedHeaders: IndexedHeaders = {}

    headers.forEach(header => {
        indexedHeaders[header.key] = {
            ...header
        }
    })

    const headerKeysInOrder = Object.keys(indexedHeaders)
    let organizedData: OrganizedItem[] = [];
    if (data != null && data.length > 0) {
        organizedData = data.map(item => {
            const organizedItem: OrganizedItem = {}

            headerKeysInOrder.forEach(key => {
                organizedItem[key] = item[key]
            })

            organizedItem.$original = item

            return organizedItem
        })
    }

    return [organizedData, indexedHeaders, headers]
}