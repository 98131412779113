import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface NoResultsModalProps {
    show: boolean;
    onClose: () => void;
}

const Resultado: React.FC<NoResultsModalProps> = ({ show, onClose }) => {
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Atenção</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Não encontramos contratos que correspondam aos filtros definidos.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Resultado;