import axios from "axios";
import {Contrato} from "../interfaces/Contrato";
import {ContratosParams} from "../interfaces/ContratosParams";

const backendURL = process.env.REACT_APP_BACKEND_URL;

export const getAllContratosFisac = async (contratos?: ContratosParams) => {
    try {
        const params: Record<string, string> = {};

        if (contratos) {
            if (contratos.ano) {
                params.ano = contratos.ano;
            }

            if (contratos.categoria) {
                params.categoria = contratos.categoria;
            }

            if (contratos.subcategoria) {
                params.subcategoria = contratos.subcategoria;
            }

            if (contratos.parametro) {
                params.parametro = contratos.parametro;
            }
        }

        const response = await axios.get<Contrato[]>(backendURL + '/lista-contratos', {
            params: params,
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar contratos:', error);
        throw new Error('Erro ao buscar contratos');
    }
};


export const getAllCategoriasContratosFisac = async (): Promise<string[]> => {

    try {
        const response = await axios.get(backendURL + `/categorias`);

        if (response.status === 200) {
            const categorias: string[] = response.data;

            return categorias;
        } else {
            throw new Error('Erro ao buscar categorias');
        }
    } catch (error) {
        console.error('Erro ao buscar categorias:', error);
        throw error;
    }
};
export const getAllSubcategoriasContratosFisac = async (categoria : String): Promise<string[]> => {

    try {
        const response = await axios.get(backendURL + `/subcategorias?categoria=` + categoria);

        if (response.status === 200) {
            const categorias: string[] = response.data;

            return categorias;
        } else {
            throw new Error('Erro ao buscar categorias');
        }
    } catch (error) {
        console.error('Erro ao buscar subcategorias:', error);
        throw error;
    }
};

export const downloadContrato = async (idArquivo: string, nomeArquivoContrato: string): Promise<any> => {
    if (!idArquivo) {
        throw new Error('ID do arquivo não fornecido');
    }

    try {
        const response = await axios.get(backendURL + '/download-contrato', {
            params: {
                id: idArquivo
            },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nomeArquivoContrato);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);

        return response.data;
    } catch (error) {
        console.error('Erro ao fazer o download do contrato:', error);
        throw new Error('Erro ao fazer o download do contrato');
    }
};

export const downloadAditivo = async (idArquivo: string, nomeArquivoContrato: string): Promise<any> => {
    if (!idArquivo) {
        throw new Error('ID do arquivo não fornecido');
    }

    try {
        const response = await axios.get(backendURL + '/download-aditivo', {
            params: {
                id: idArquivo
            },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nomeArquivoContrato);
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);

        return response.data;
    } catch (error) {
        console.error('Erro ao fazer o download do contrato:', error);
        throw new Error('Erro ao fazer o download do contrato');
    }
};

export const downloadArquivosContrato = async (idArquivo: number): Promise<any> => {
    if (!idArquivo) {
        throw new Error('ID do arquivo não fornecido');
    }

    try {
        const response = await axios.get(backendURL + '/download-arquivos', {
            params: {
                id: idArquivo
            },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'arquivo.zip');
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);

        return response.data;
    } catch (error) {
        console.error('Erro ao fazer o download do contrato:', error);
        throw new Error('Erro ao fazer o download do contrato');
    }
};

